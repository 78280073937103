<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <b-row>
          <b-col
            cols="12"
            md="12"
          >
            <b-button
              v-if="$ability.can('update', 'categories')"
              variant="primary"
              class="mb-2"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="saveList"
            >
              {{ $t('general.save') }}
            </b-button>
            <b-button
              v-if="$ability.can('store', 'categories')"
              variant="warning"
              class="mb-2 float-right"
              :to="{ name: 'attribute-categories-store' }"
            >
              <span class="text-nowrap">{{ $t('general.add') }}</span>
            </b-button>
          </b-col>
          <b-col
            v-if="$ability.can('index', 'categories')"
            cols="12"
            md="12"
          >
            <div class="halo-tree">
              <draggable
                v-model="categories"
                group="tree"
              >
                <div
                  v-for="level1 in categories"
                  :key="level1.id"
                  class="li"
                >
                  <div class="tree-node-el">
                    <div>
                      <span>{{ level1.title }}</span>
                    </div>
                    <div>
                      <router-link
                        v-if="$ability.can('update', 'categories')"
                        :to="{ name: 'attribute-categories-update', params: { id: level1.id } }"
                        class="btn btn-primary btn-sm"
                      >
                        <feather-icon
                          icon="EditIcon"
                          size="14"
                          class-name="mr-50"
                        />
                      </router-link>
                      <button
                        v-if="$ability.can('destroy', 'categories')"
                        class="btn btn-danger btn-sm"
                        @click="destroy(level1.id)"
                      >
                        <feather-icon
                          icon="Trash2Icon"
                          size="14"
                          class-name="mr-50"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </draggable>
            </div>
          </b-col>
          <b-col
            v-if="$ability.can('update', 'categories')"
            cols="12"
            md="12"
          >
            <b-button
              variant="primary"
              class="mt-2"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="saveList"
            >
              {{ $t('general.save') }}
            </b-button>
          </b-col>
        </b-row>

      </div>
    </b-card>
  </div>
</template>

<script>
import GlobalMixin from '@/mixins/GlobalMixin'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  mixins: [GlobalMixin],
  data() {
    return {
      categories: [],
      apiUrl: process.env.VUE_APP_APP_URL,
    }
  },
  beforeCreate() {
    this.$http.get('/api/admin/categories', {
      params: {
        type: 'attribute',
      },
    })
      .then(response => {
        this.categories = response.data
      })
  },
  methods: {
    destroy(id) {
      this.confirm(() => {
        this.$http.delete(`/api/admin/categories/${id}`)
          .then(() => this.fetchData())
          .catch(error => {
            this.fetchData()
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: error.response.data.message,
                icon: 'AlertCircleIcon',
                variant: 'warning',
              },
            })
          })
      })
    },
    fetchData() {
      this.$http.get('/api/admin/categories', {
        params: {
          type: 'attribute',
        },
      })
        .then(response => {
          this.categories = response.data
        })
    },
    saveList() {
      const data = { categories: this.mapCat(this.categories) }
      this.$http.post('/api/admin/sort_categories', data)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Успішно',
              icon: 'AlertCircleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
        })
    },
    mapCat(categories, parent_id = null) {
      return categories.map(item => ({
        id: item.id,
        parent_id,
        children: this.mapCat(item.children, item.id),
      }))
    },
  },
}
</script>
